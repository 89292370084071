exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-1-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/1/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-1-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-10-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/10/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-10-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-11-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/11/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-11-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-12-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/12/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-12-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-13-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/13/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-13-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-2-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/2/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-2-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-3-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/3/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-3-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-4-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/4/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-4-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-5-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/5/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-5-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-6-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/6/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-6-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-7-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/7/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-7-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-8-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/8/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-8-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-9-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/9/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-9-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-projects-1-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/projects/1/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-storage-projects-1-index-mdx" */),
  "component---src-pages-categories-index-tsx": () => import("./../../../src/pages/categories/index.tsx" /* webpackChunkName: "component---src-pages-categories-index-tsx" */),
  "component---src-pages-categories-slug-tsx": () => import("./../../../src/pages/categories/slug.tsx" /* webpackChunkName: "component---src-pages-categories-slug-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cv-index-tsx": () => import("./../../../src/pages/cv/index.tsx" /* webpackChunkName: "component---src-pages-cv-index-tsx" */),
  "component---src-pages-cv-persian-index-tsx": () => import("./../../../src/pages/cv/persian/index.tsx" /* webpackChunkName: "component---src-pages-cv-persian-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-1-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/1/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-1-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-10-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/10/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-10-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-11-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/11/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-11-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-12-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/12/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-12-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-13-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/13/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-13-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-2-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/2/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-2-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-3-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/3/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-3-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-4-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/4/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-4-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-5-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/5/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-5-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-6-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/6/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-6-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-7-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/7/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-7-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-8-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/8/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-8-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-9-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/blog/9/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-blog-9-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-projects-1-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/amir/Desktop/Projects/amirsalehi/storage/projects/1/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-storage-projects-1-index-mdx" */),
  "component---src-pages-videos-index-tsx": () => import("./../../../src/pages/videos/index.tsx" /* webpackChunkName: "component---src-pages-videos-index-tsx" */)
}

